import { Link } from 'react-router-dom';
import { Music } from 'lucide-react';
import AuthComponent from './AuthComponent';

export const Header = () => {
  return (
    <nav className="fixed w-full bg-white/80 backdrop-blur-md z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Left side - Logo */}
          <div className="flex items-center space-x-2">
            <Music className="h-8 w-8 text-indigo-600" />
            <span className="text-2xl font-bold text-gray-900">AllMyGigs</span>
          </div>

          {/* Right side - Auth Component */}
          <div className="flex items-center">
            <AuthComponent />
          </div>
        </div>
      </div>
    </nav>
  );
};
