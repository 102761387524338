import { Loader2, Upload } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/components/AuthProvider';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { RegistrationResponse, UsernameCheckResponse } from '@/types/api';

const RegistrationPage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const [isCheckingUsername, setIsCheckingUsername] = useState(false);
    const [usernameError, setUsernameError] = useState<string | null>(null);

    const [formData, setFormData] = useState({
        username: '',
        preferredName: '',
        bio: '',
    });

    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    // Username validation rules
    const validateUsername = (username: string) => {
        if (username.length < 3) return "Username must be at least 3 characters";
        if (username.length > 30) return "Username must be less than 30 characters";
        if (!/^[a-zA-Z0-9_-]+$/.test(username)) return "Username can only contain letters, numbers, underscores, and hyphens";
        return null;
    };

    useEffect(() => {
        const checkUsername = async () => {
            if (!formData.username || validateUsername(formData.username)) {
                setUsernameError(null);
                return;
            }

            setIsCheckingUsername(true);
            try {
                const response = await fetch(`/api/validate-username/${formData.username}`);
                const data = await response.json() as UsernameCheckResponse;

                if (!data.available) {
                    setUsernameError("This username is already taken");
                } else {
                    setUsernameError(null);
                }
            } catch (err) {
                console.error('Username check error:', err);
                setUsernameError("Error checking username availability");
            } finally {
                setIsCheckingUsername(false);
            }
        };

        const timeoutId = setTimeout(checkUsername, 500);
        return () => clearTimeout(timeoutId);
    }, [formData.username]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase().trim();
        setFormData(prev => ({
            ...prev,
            username: value
        }));
        const validationError = validateUsername(value);
        if (validationError) {
            setUsernameError(validationError);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!user?.email || usernameError || isCheckingUsername) return;

        setIsLoading(true);
        setError(null);

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('email', user.email);
            formDataToSend.append('username', formData.username);
            formDataToSend.append('preferredName', formData.preferredName);
            formDataToSend.append('bio', formData.bio || '');
            if (imageFile) {
                formDataToSend.append('imageFile', imageFile);
            }
            formDataToSend.append('googleImageUrl', user.imageUrl || '')

            const response = await fetch('/api/register', {
                method: 'POST',
                body: formDataToSend,
            });

            if (!response.ok) {
                const data: RegistrationResponse = await response.json();
                throw new Error(data.message || 'Registration failed');
            }

            setSuccess(true);
            setTimeout(() => {
                navigate('/');
            }, 2000);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
            <Card className="w-full max-w-md">
                <CardHeader>
                    <CardTitle>Complete Your Profile</CardTitle>
                    <CardDescription>
                        Add some details to personalize your AllMyGigs account
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="flex flex-col items-center space-y-4">
                            <Avatar className="w-24 h-24">
                                <AvatarImage src={imagePreview || user?.imageUrl} />
                                <AvatarFallback>
                                    {formData.preferredName?.charAt(0) || user?.name?.charAt(0) || '?'}
                                </AvatarFallback>
                            </Avatar>

                            <div className="flex items-center space-x-2">
                                <Input
                                    id="picture"
                                    type="file"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={handleImageChange}
                                />
                                <Button
                                    type="button"
                                    variant="outline"
                                    onClick={() => document.getElementById('picture')?.click()}
                                >
                                    <Upload className="w-4 h-4 mr-2" />
                                    Upload Picture
                                </Button>
                            </div>
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="username">Username</Label>
                            <Input
                                id="username"
                                value={formData.username}
                                onChange={handleUsernameChange}
                                required
                                placeholder="Choose your username"
                                className={usernameError ? "border-red-500" : ""}
                            />
                            {isCheckingUsername && (
                                <p className="text-sm text-gray-500">Checking availability...</p>
                            )}
                            {usernameError && (
                                <p className="text-sm text-red-500">{usernameError}</p>
                            )}
                            {formData.username && !usernameError && !isCheckingUsername && (
                                <p className="text-sm text-green-500">Username is available!</p>
                            )}
                            <p className="text-sm text-gray-500">
                                This will be your profile URL: allmygigs.com/{formData.username}
                            </p>
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="preferredName">Preferred Name</Label>
                            <Input
                                id="preferredName"
                                value={formData.preferredName}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    preferredName: e.target.value
                                }))}
                                required
                                placeholder="How should we call you?"
                            />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="bio">Bio (Optional)</Label>
                            <Textarea
                                id="bio"
                                value={formData.bio}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    bio: e.target.value
                                }))}
                                placeholder="Tell us a bit about yourself..."
                                className="h-32"
                            />
                        </div>

                        {error && (
                            <Alert variant="destructive">
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        )}

                        {success && (
                            <Alert>
                                <AlertDescription>
                                    Profile updated successfully! Redirecting...
                                </AlertDescription>
                            </Alert>
                        )}

                        <Button
                            type="submit"
                            className="w-full"
                            disabled={isLoading || !formData.preferredName || !formData.username || !!usernameError || isCheckingUsername}
                        >
                            {isLoading && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
                            Complete Registration
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default RegistrationPage;