import { Music, Calendar, Share2, ArrowRight } from 'lucide-react';
import AuthComponent from '../components/AuthComponent';
import { GetTickets } from '@/types/Strings';

const LandingPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
            {/* Navigation */}
            <nav className="fixed w-full bg-white/80 backdrop-blur-md z-50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16">
                        {/* Left side - Logo */}
                        <div className="flex items-center space-x-2">
                            <Music className="h-8 w-8 text-indigo-600" />
                            <span className="text-2xl font-bold text-gray-900">AllMyGigs</span>
                        </div>

                        {/* Right side - Auth Component */}
                        <div className="flex items-center">
                            <AuthComponent />
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="pt-32 pb-16 text-center px-4">
                <h1 className="text-4xl sm:text-6xl font-bold text-gray-900 mb-6">
                    One Link For All
                    <br />
                    Your <span className="text-indigo-600">Music Events</span>
                </h1>
                <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
                    Share your upcoming gigs, concerts, and musical events with your fans. One simple link to connect your audience with your performances.
                </p>
                <button className="bg-indigo-600 text-white px-8 py-3 rounded-full hover:bg-indigo-700 transition-colors text-lg font-medium inline-flex items-center">
                    Get Started Free
                    <ArrowRight className="ml-2 h-5 w-5" />
                </button>
            </div>

            {/* Example Preview */}
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                    {/* Example Cards */}
                    <div className="space-y-4">
                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
                            <div className="flex items-start justify-between">
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-900">Jazz Night at Blue Note</h3>
                                    <p className="text-gray-600 flex items-center mt-2">
                                        <Calendar className="h-4 w-4 mr-2" />
                                        Tomorrow, 8:00 PM
                                    </p>
                                </div>
                                <button className="bg-indigo-100 text-indigo-600 px-4 py-1 rounded-full text-sm font-medium">
                                    {GetTickets}
                                </button>
                            </div>
                        </div>

                        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
                            <div className="flex items-start justify-between">
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-900">Summer Festival</h3>
                                    <p className="text-gray-600 flex items-center mt-2">
                                        <Calendar className="h-4 w-4 mr-2" />
                                        Next Week, 6:00 PM
                                    </p>
                                </div>
                                <button className="bg-indigo-100 text-indigo-600 px-4 py-1 rounded-full text-sm font-medium">
                                    {GetTickets}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Features List */}
                    <div className="space-y-8">
                        <div className="space-y-6">
                            <h2 className="text-3xl font-bold text-gray-900">
                                Everything you need to share your music journey
                            </h2>
                            <div className="space-y-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        <div className="bg-indigo-100 p-2 rounded-lg">
                                            <Calendar className="h-6 w-6 text-indigo-600" />
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h3 className="text-lg font-medium text-gray-900">Easy Event Management</h3>
                                        <p className="mt-1 text-gray-500">Add and update your gigs in seconds. Keep your fans informed about your latest performances.</p>
                                    </div>
                                </div>

                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        <div className="bg-indigo-100 p-2 rounded-lg">
                                            <Share2 className="h-6 w-6 text-indigo-600" />
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h3 className="text-lg font-medium text-gray-900">One Link to Share</h3>
                                        <p className="mt-1 text-gray-500">Share a single link across all your social media platforms. Make it easy for fans to find your events.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Call to Action */}
            <div className="bg-indigo-600 text-white py-16 mt-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-3xl font-bold mb-4">Ready to share your music with the world?</h2>
                    <p className="text-xl text-indigo-100 mb-8">Join thousands of musicians who trust AllMyGigs to connect with their audience.</p>
                    <button className="bg-white text-indigo-600 px-8 py-3 rounded-full hover:bg-indigo-50 transition-colors text-lg font-medium">
                        Create Your Page
                    </button>
                </div>
            </div>

            {/* Footer */}
            <footer className="bg-white py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-2">
                            <Music className="h-6 w-6 text-indigo-600" />
                            <span className="text-xl font-bold text-gray-900">AllMyGigs</span>
                        </div>
                        <div className="text-gray-500">© 2024 AllMyGigs. All rights reserved.</div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;