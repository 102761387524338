import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { UserPage } from './pages/UserPage';
import LandingPage from './pages/LandingPage';
import RegistrationPage from './pages/RegistrationPage';
import { AuthProvider, useAuth } from './components/AuthProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import EventCreationPage from './pages/EventCreationPage';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <GoogleOAuthProvider clientId='765620806017-p6465k0a8o8ff611b9a87g57o0ue64fn.apps.googleusercontent.com'>
          <AuthProvider>
            <div className="min-h-screen">
              <main>
                <Routes>
                  {/* Conditional route to redirect based on authentication state */}
                  <Route path="/" element={<LandingPage />} />

                  {/* Authenticated route example for user dashboard */}
                  <Route path="/dashboard" element={<ProtectedRoute><div>Dashboard Coming Soon</div></ProtectedRoute>} />
                  <Route path="/create-event" element={<EventCreationPage />} />

                  {/* UserPage path */}
                  <Route path="/:username" element={<UserPage />} />

                  {/* Registration page for first-time users */}
                  <Route path="/register" element={<RegistrationPage />} />



                  {/* Redirect unknown paths to landing page */}
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </main>
            </div>
          </AuthProvider>
        </GoogleOAuthProvider>
      </Router>
    </QueryClientProvider>
  );
}

// ProtectedRoute component to check authentication before allowing access to certain routes
const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, login } = useAuth();

  if (!isAuthenticated) {
    login(); // Trigger login if user is not authenticated
    return <Navigate to="/" replace />;
  }

  return children;
};

export default App;
