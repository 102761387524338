import { Loader2, Upload } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { EventCreationResponse } from '@/types/api';
import { useAuth } from '@/components/AuthProvider';

const EventCreationPage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);

    const [formData, setFormData] = useState({
        title: '',
        venue: '',
        eventDate: '',
        startTime: '',
        ticketUrl: '',
        eventUrl: '',
        description: '',
        userId: '',
    });

    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const formDataToSend = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                formDataToSend.append(key, value);
            });

            if (imageFile) {
                formDataToSend.append('imageFile', imageFile);
            }
            if (user) {
                formDataToSend.set('userId', String(user.id));
            }
            const response = await fetch('/api/create-event', {
                method: 'POST',
                body: formDataToSend,
            });

            if (!response.ok) {
                const data: EventCreationResponse = await response.json();
                throw new Error(data.message || 'Failed to create event');
            }

            setSuccess(true);
            setTimeout(() => {
                navigate('/events');
            }, 2000);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
            <Card className="w-full max-w-2xl">
                <CardHeader>
                    <CardTitle>Create New Event</CardTitle>
                    <CardDescription>
                        Fill in the details for your new event
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="flex flex-col items-center space-y-4">
                            {imagePreview && (
                                <img
                                    src={imagePreview}
                                    alt="Event preview"
                                    className="w-full max-w-md h-48 object-cover rounded-lg"
                                />
                            )}
                            <div className="flex items-center space-x-2">
                                <Input
                                    id="picture"
                                    type="file"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={handleImageChange}
                                />
                                <Button
                                    type="button"
                                    variant="outline"
                                    onClick={() => document.getElementById('picture')?.click()}
                                >
                                    <Upload className="w-4 h-4 mr-2" />
                                    Upload Event Image
                                </Button>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="title">Event Title</Label>
                                <Input
                                    id="title"
                                    value={formData.title}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        title: e.target.value
                                    }))}
                                    required
                                    placeholder="Enter event title"
                                />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="venue">Venue</Label>
                                <Input
                                    id="venue"
                                    value={formData.venue}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        venue: e.target.value
                                    }))}
                                    required
                                    placeholder="Enter venue name"
                                />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="eventDate">Event Date</Label>
                                <Input
                                    id="eventDate"
                                    type="date"
                                    value={formData.eventDate}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        eventDate: e.target.value
                                    }))}
                                    required
                                />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="startTime">Start Time</Label>
                                <Input
                                    id="startTime"
                                    type="time"
                                    value={formData.startTime}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        startTime: e.target.value
                                    }))}
                                    required
                                />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="ticketUrl">Ticket URL (Optional)</Label>
                                <Input
                                    id="ticketUrl"
                                    type="url"
                                    value={formData.ticketUrl}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        ticketUrl: e.target.value
                                    }))}
                                    placeholder="https://..."
                                />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="eventUrl">Event URL (Optional)</Label>
                                <Input
                                    id="eventUrl"
                                    type="url"
                                    value={formData.eventUrl}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        eventUrl: e.target.value
                                    }))}
                                    placeholder="https://..."
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="description">Event Description</Label>
                            <Textarea
                                id="description"
                                value={formData.description}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    description: e.target.value
                                }))}
                                placeholder="Describe your event..."
                                className="h-32"
                            />
                        </div>

                        {error && (
                            <Alert variant="destructive">
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        )}

                        {success && (
                            <Alert>
                                <AlertDescription>
                                    Event created successfully! Redirecting...
                                </AlertDescription>
                            </Alert>
                        )}

                        <Button
                            type="submit"
                            className="w-full"
                            disabled={isLoading || !formData.title || !formData.venue || !formData.eventDate || !formData.startTime}
                        >
                            {isLoading && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
                            Create Event
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default EventCreationPage;