import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

interface AMGUser {
    id: number;
    name: string;
    email: string;
    imageUrl?: string;
    preferredName?: string;
    bio?: string;
    username?: string;
    isDeleted?: boolean;
}

interface GoogleUserInfo {
    sub: string;
    name: string;
    email: string;
    picture: string;
    given_name?: string;
    family_name?: string;
    locale?: string;
    email_verified?: boolean;
}

interface UserCheckResponse {
    userId: number;
    error?: string;
    details?: string;
}

interface AuthContextType {
    user: AMGUser | null;
    isAuthenticated: boolean;
    login: () => void;
    logout: () => void;
    isLoading: boolean;
    updateUserProfile: (updates: Partial<AMGUser>) => Promise<void>;
}

const AUTH_STORAGE_KEY = 'auth_user';
const TOKEN_STORAGE_KEY = 'auth_token';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<AMGUser | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    // Load saved auth state on mount
    useEffect(() => {
        const loadSavedAuth = () => {
            try {
                const savedUser = localStorage.getItem(AUTH_STORAGE_KEY);
                const savedToken = localStorage.getItem(TOKEN_STORAGE_KEY);

                if (savedUser && savedToken) {
                    setUser(JSON.parse(savedUser));
                }
            } catch (error) {
                console.error('Error loading saved auth state:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadSavedAuth();
    }, []);

    const saveAuthState = (userData: AMGUser, token: string) => {
        localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(userData));
        localStorage.setItem(TOKEN_STORAGE_KEY, token);
    };

    const clearAuthState = () => {
        localStorage.removeItem(AUTH_STORAGE_KEY);
        localStorage.removeItem(TOKEN_STORAGE_KEY);
    };

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            try {
                setIsLoading(true);
                const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${tokenResponse.access_token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const userInfo = await response.json() as GoogleUserInfo;
                const userId = await getUserIdIfExists(userInfo.email);

                // Check if it's the user's first login
                if (userId == -1) {
                    navigate('/register');
                }
                // Save auth state before setting user
                const profile: AMGUser = {
                    id: userId,
                    name: userInfo.name,
                    email: userInfo.email,
                    imageUrl: userInfo.picture,
                };
                saveAuthState(profile, tokenResponse.access_token);
                setUser(profile);
            } catch (error) {
                console.error('Error fetching user data:', error);
                alert('Failed to get user information');
            } finally {
                setIsLoading(false);
            }
        },
        onError: (error) => {
            console.error('Login failed:', error);
            alert('Login failed. Please try again.');
            setIsLoading(false);
        },
        scope: 'email profile',
    });

    const logout = () => {
        setIsLoading(true);
        googleLogout();
        clearAuthState();
        setUser(null);
        navigate('/');
        setIsLoading(false);
    };

    const getUserIdIfExists = async (email: string): Promise<number> => {
        try {
            const response = await fetch(`/api/user-exists/${email}`);

            if (!response.ok) {
                throw new Error('Failed to check user status');
            }
            const data = await response.json() as UserCheckResponse;
            return data.userId;
        } catch (error) {
            console.error('Error checking user status:', error);
            return -1;
        }
    };

    const updateUserProfile = async (updates: Partial<AMGUser>): Promise<void> => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem(TOKEN_STORAGE_KEY);

            if (!user || !token) {
                throw new Error('No authenticated user');
            }

            const response = await fetch(`/api/users/${user.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updates),
            });

            if (!response.ok) {
                throw new Error('Failed to update profile');
            }

            const updatedUser = { ...user, ...updates };
            setUser(updatedUser);
            saveAuthState(updatedUser, token);
        } catch (error) {
            console.error('Error updating profile:', error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthenticated: !!user,
                login,
                logout,
                isLoading,
                updateUserProfile,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};