import { Calendar, MapPin, Link as LinkIcon } from 'lucide-react';
import { Header } from '@/components/Header';
import { GetTickets } from '@/types/Strings';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { EventResponse } from '@/types/api';

interface Event {
  event_id: number;
  title: string;
  venue: string;
  event_date: string;
  start_time: string;
  ticket_url: string | null;
  event_url: string | null;
  description: string | null;
  image_url: string | null;
  created_at: string;
  updated_at: string;
}

export const UserPage = () => {
  const { username } = useParams();
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`/api/get-events/${username}`);
        const data: EventResponse = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Failed to fetch events');
        }

        setEvents(data.events);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch events');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [username]);

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className="flex-1 bg-gray-50 pt-12 flex items-center justify-center">
          <div className="text-gray-600">Loading events...</div>
        </main>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className="flex-1 bg-gray-50 pt-12 flex items-center justify-center">
          <div className="text-red-600">{error}</div>
        </main>
      </div>
    );
  }

  const formatDate = (dateStr: string) => {
    return new Date(dateStr).toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatTime = (timeStr: string) => {
    return new Date(`1970-01-01T${timeStr}`).toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: '2-digit'
    });
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-1 bg-gray-50 pt-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {events.length === 0 ? (
            <div className="text-center text-gray-600">
              No events found for this user.
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {events.map((event) => (
                <div
                  key={event.event_id}
                  className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                >
                  {event.image_url && (
                    <div className="aspect-w-16 aspect-h-9">
                      <img
                        src={event.image_url}
                        alt={event.title}
                        className="object-cover w-full h-48"
                      />
                    </div>
                  )}
                  <div className="p-6">
                    <h2 className="text-xl font-semibold text-gray-900 mb-2">
                      {event.title}
                    </h2>
                    <p className="text-gray-600 mb-4">{event.venue}</p>
                    <div className="flex items-center text-gray-600 mb-2">
                      <Calendar className="h-5 w-5 mr-2" />
                      <span>{formatDate(event.event_date)} at {formatTime(event.start_time)}</span>
                    </div>
                    <div className="flex items-center text-gray-600 mb-4">
                      <MapPin className="h-5 w-5 mr-2" />
                      <span>{event.venue}</span>
                    </div>
                    {event.description && (
                      <p className="text-gray-600 mb-4 line-clamp-3">{event.description}</p>
                    )}
                    <div className="flex gap-2">
                      {event.ticket_url && (
                        <a
                          href={event.ticket_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex-1 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors text-center"
                        >
                          {GetTickets}
                        </a>
                      )}
                      {event.event_url && (
                        <a
                          href={event.event_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex-none bg-gray-100 text-gray-600 py-2 px-4 rounded-md hover:bg-gray-200 transition-colors"
                          title="Event details"
                        >
                          <LinkIcon className="h-5 w-5" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </main>
    </div>
  );
};